import { useStore } from '@blissbook/ui/util/store'
import type { DirectReportPerson } from './store'

export const useAudienceDashboardSettings = () =>
  useStore('audienceDashboardSettings')

export const useDirectReports = () =>
  useStore('directReports') as DirectReportPerson[] | undefined

export const useFeatureFlagIds = () => useStore('featureFlagIds') as string[]

export const useHandbooks = () => useStore('handbooks') as IHandbook[]

export type ManagerSettings = {
  allowNotificationsToManagers: boolean
  allowNotificationsToReports: boolean
}

export const useManagerSettings = () =>
  useStore('managerSettings') as ManagerSettings

export const useOrganization = () => useStore('organization') as IOrganization

export const useSession = () => useStore('session')
