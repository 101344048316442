import { calculateLogoWidth } from '@blissbook/lib/branding'
import { colors, gridBreakpoints } from '@blissbook/ui/branding'
import {
  useAudienceDashboardSettings,
  useOrganization,
} from '@blissbook/ui/dashboard/hooks'
import { Head, Link } from '@blissbook/ui/lib'
import { cx } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { type CSSProperties, Fragment } from 'react'
import { transitionDuration, transparentInput } from './branding'

export const Layout: React.FC<{
  className?: string
  id?: string
  favicon?: string
}> = ({ children, className, id, ...props }) => {
  const organization = useOrganization()
  return (
    <Fragment>
      <Head {...props} title={`${organization.name} Dashboard`} />
      <div id='main'>
        <article children={children} className={className} id={id} />
      </div>
    </Fragment>
  )
}

export const AudienceHeader: React.FC = ({ children }) => {
  const { primaryColor, bannerImage } = useAudienceDashboardSettings()
  return (
    <section
      className='tw-pt-2 tw-pb-8 tw-text-white lg:tw-py-20'
      style={{
        backgroundColor: primaryColor,
        backgroundImage: bannerImage ? `url(${bannerImage.url})` : undefined,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 100%',
        backgroundSize: 'cover',
      }}
    >
      <div className='container' style={{ maxWidth: 800 }}>
        {children}
      </div>
    </section>
  )
}

export const OrganizationLogo: React.FC<{
  className?: string
  style?: CSSProperties
}> = ({ children, ...props }) => {
  const { logoImage } = useAudienceDashboardSettings()
  const organization = useOrganization()
  return (
    <div {...props}>
      <Choose>
        <When condition={logoImage}>
          <img
            alt='Logo'
            src={logoImage.url}
            style={{
              width: calculateLogoWidth(logoImage, {
                minWidth: 90,
                maxWidth: 180,
              }),
            }}
          />
        </When>
        <Otherwise>
          <h1
            className='ellipsis tw-my-0 tw-py-1 tw-text-white'
            style={{ maxWidth: 360 }}
            title={organization.name}
          >
            {organization.name}
          </h1>
        </Otherwise>
      </Choose>
    </div>
  )
}

export const HeaderLink: React.FC<{
  className?: string
  href?: string
  to?: string
}> = ({ children, className, ...props }) => (
  <Link
    {...props}
    className={cx(
      'tw-flex tw-items-center tw-justify-center tw-pt-0.5 tw-pb-1 tw-px-4',
      className,
    )}
    css={{
      background: transparentInput.backgroundColor,
      borderRadius: 15,
      // padding: '0px 10px',
      transition: `all ${transitionDuration} linear`,
      '&:hover': {
        background: transparentInput.backgroundHoverColor,
      },
      [`@media (max-width: ${gridBreakpoints.lg}px)`]: {
        fontSize: 14,
      },
    }}
    style={{
      color: colors.white,
      textDecoration: 'none',
    }}
  >
    {children}
    <FontAwesomeIcon
      className='tw-ml-2 tw-mt-0.5'
      icon='arrow-right'
      style={{ color: 'white', fontSize: 11 }}
    />
  </Link>
)
