import config from '@blissbook/ui-config'
import {
  AudienceHeader,
  HeaderLink,
  Layout,
  OrganizationLogo,
} from '@blissbook/ui/dashboard/components'
import {
  useAudienceDashboardSettings,
  useFeatureFlagIds,
  useHandbooks,
  useOrganization,
  useSession,
} from '@blissbook/ui/dashboard/hooks'
// @ts-ignore: WIP imports
import { Dropdown, Link, SignOutLink } from '@blissbook/ui/lib'
import { useStore } from '@blissbook/ui/util/store'
import classnames from 'classnames'
import React from 'react'
import { useState } from 'react'
import { AudienceHandbook } from './handbook'
import { SearchBar } from './search'

type HandbookSortingOption = {
  id: number
  label: string
}

const handbookSortingOptions: HandbookSortingOption[] = [
  { id: 0, label: 'Name' },
  { id: 1, label: 'Last Updated' },
]

export const AudienceHandbookSortingDropdown: React.FC = () => {
  const [item, setItem] = useState(handbookSortingOptions[0])

  return (
    <Dropdown.Provider>
      <Dropdown.ToggleButton className='btn btn-input' style={{ width: 170 }}>
        {item.label}
      </Dropdown.ToggleButton>
      <Dropdown.Menu maxHeight={200} sameWidth>
        {handbookSortingOptions.map((option: HandbookSortingOption) => {
          return (
            // biome-ignore lint/a11y/useKeyWithClickEvents: requires some work
            <div
              key={option.id}
              className={classnames(
                'dropdown-item tw-flex tw-items-center tw-justify-between',
                { active: item === option },
              )}
              onClick={() => {
                setItem(option)
              }}
            >
              {option.label}
            </div>
          )
        })}
      </Dropdown.Menu>
    </Dropdown.Provider>
  )
}

const Header: React.FC = () => {
  const featureFlagIds = useFeatureFlagIds()
  const handbooks = useHandbooks()
  const organization = useOrganization()
  const session = useSession()
  const { person } = session

  const canAdmin = [
    session.canAdminBlissbook,
    person?.isOrgCollaborator,
    handbooks.some((h) => h.permissionIds.includes('handbook.draft.edit')),
  ].some(Boolean)

  return (
    <AudienceHeader>
      <div className='tw-flex tw-items-start tw-justify-between tw-mb-4'>
        <OrganizationLogo
          className='tw-mr-2'
          style={{ flex: 1, minWidth: 0 }}
        />

        <If condition={Boolean(session.user)}>
          <div className='tw-flex tw-flex-col tw-items-end'>
            <Dropdown.Provider>
              <Dropdown.ToggleButton className='tw-text-white'>
                Hi, {session.firstName}!
              </Dropdown.ToggleButton>
              <Dropdown.Menu placement='bottom-end'>
                <If condition={organization.showPrivacyPolicy}>
                  <a
                    className='dropdown-item'
                    href={config.origin + '/privacy-policy'}
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    Privacy Policy
                  </a>
                  <div className='dropdown-divider' />
                </If>

                <SignOutLink className='dropdown-item'>Sign Out</SignOutLink>
              </Dropdown.Menu>
            </Dropdown.Provider>

            <If condition={canAdmin}>
              <HeaderLink className='tw-mt-2 tw-hidden md:tw-flex' href='/home'>
                Admin Dashboard
              </HeaderLink>
            </If>
            <If
              condition={
                featureFlagIds.includes('allowManagers') && person?.isManager
              }
            >
              <HeaderLink className='tw-mt-2' to='/manager'>
                Manager Dashboard
              </HeaderLink>
            </If>
          </div>
        </If>
      </div>

      <SearchBar />
    </AudienceHeader>
  )
}

export const AudienceDashboardPage = () => {
  const canSignIn = useStore('canSignIn')
  const { faviconImage } = useAudienceDashboardSettings()
  const handbooks = useHandbooks()
  const session = useSession()

  return (
    <Layout favicon={faviconImage?.url}>
      <Header />
      <div className='container lg:tw-mt-14' style={{ maxWidth: 800 }}>
        {handbooks.map((handbook) => (
          <AudienceHandbook handbook={handbook} key={handbook.id} />
        ))}

        <If condition={!session.user && canSignIn}>
          <p className='text-center tw-my-4'>
            You must <Link href='/sign-in'>sign in</Link> to view other
            handbooks/documents.
          </p>
        </If>
      </div>
    </Layout>
  )
}
