import { library } from '@fortawesome/fontawesome-svg-core'
import * as light from './light'
import * as regular from './regular'
import * as solid from './solid'

library.add(
  ...Object.values(light),
  ...Object.values(regular),
  ...Object.values(solid),
)
