import { AudienceDashboardPage } from './pages/audience'
import { ManagerDashboardPage } from './pages/manager'

export const routes = [
  {
    path: '/',
    exact: true,
    component: AudienceDashboardPage,
  },
  {
    path: '/manager',
    exact: true,
    component: ManagerDashboardPage,
  },
]
