import { ApolloProvider } from '@apollo/client'
import { client as apolloClient } from '@blissbook/ui/util/apollo'
import React from 'react'
import { hot } from 'react-hot-loader/root'
import { renderRoutes } from 'react-router-config'
import { Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { routes } from './routes'
import './icons'

export const DashboardPage = () => (
  <ApolloProvider client={apolloClient}>
    <Switch>{renderRoutes(routes)}</Switch>
    <ToastContainer />
  </ApolloProvider>
)

export const HotDashboardPage = hot(DashboardPage)
