import {
  type HandbookSearchResult,
  SearchHandbooksDocument,
  type SearchHandbooksQuery,
} from '@blissbook/ui/dashboard/graph'
import { client } from '@blissbook/ui/util/apollo'

export async function searchHandbooks(
  search: string,
): Promise<HandbookSearchResult[]> {
  const res = await client.query<SearchHandbooksQuery>({
    fetchPolicy: 'no-cache',
    query: SearchHandbooksDocument,
    variables: { search },
  })

  return res.data.searchHandbooks
}
