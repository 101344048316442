import Handbook from '@blissbook/common/handbook'
import { Session } from '@blissbook/common/session'
import { Store } from '@blissbook/ui/util/store'

export type DirectReportHandbook = {
  id: number
  name: string
}

export type DirectReportHandbookRecipient = {
  handbook: DirectReportHandbook
  hasValidSignature: boolean
  isSignatureRequired: boolean
  lastSignedAt: Date | null
  score: number
  signatureDueAt: Date | null
  signatureRoundId: number | null
}

export type DirectReportPerson = {
  employeeId: string | null
  fullName: string
  id: number
  handbookRecipients: DirectReportHandbookRecipient[]
}

export const createStore = ({ handbooks, session, ...initialState }: any) =>
  Store.create({
    ...initialState,
    handbooks: handbooks.map(Handbook.fromJSON),
    session: Session.fromJSON(session),
  })
